<template>
  <div>
    <div v-if="model === false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Composite Diverting Technology
              </div>
              <div class="title2">This is a technology to change the direction of fracture propagation and promote
                initiating new fractures through temporary plugging agent. The fracture complexity and the stimulated
                volume of the reservoir will be remarkably improved by this technology to increase production.</div>
              <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
                Product inquiry
              </div>
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>

          <div class="screenGpts" style="background-color: #fff;" ref="consultMove">
            <div style="display: flex;margin:0 auto;">
              <div style="display: flex;margin:0 auto 0 auto;text-align: center;">
                <!--              <div style="height:2.2vw;text-align: right"><img loading="lazy" src="@/assets/icon_first.png">-->
                <!--              </div>-->
                <div class="title">&nbsp;Composite Diverting Technology
                </div>
              </div>
            </div>

            <div class="pro3">

              <!--油田-->
              <div class="grand2">
                <div class="back " style="background-color: #bfd8f9;display: unset">
                  <div class="photo" style="background-color: white;padding: 1vh 0.5vw;width: 100%">
                    <img loading="lazy"
                      style="border-top-left-radius: 9px;border-top-right-radius: 9px;height: 30vh;object-fit: contain;"
                      src="@/assets/subordinate/conventional1.png" />
                  </div>

                  <div class="titleDesign" style="float: unset; width: 70%;">
                    <div class="title1">Technical Principle</div>
                    <div class="title2">
                      <li>Soluble temporary plugging knot is a synthetic polymer with knot shape, inject soluble
                        temporary plugging knot during fracturing (acid fracturing) process, effectively seal
                        perforations and near wellbore fracture to divert fluid to initiate new fracture, improve
                        homogeneous stimulation result of different properties, formation stress and clusters.</li>
                    </div>
                    <div class="title1">Application Method</div>
                    <div class="title2">
                      <li>After the completion of fracturing in previous stage, the compound temporary plugging agent is
                        pumped before pump new pad. The temporary plugging agent gathers at the designed position to
                        form a sealing layer and increase downhole net pressure, change the extension direction of the
                        fracture and promote the opening of new fractures. The compound temporary plugging agent breaks
                        automatically after the completion of stimulation and restores the connectivity of the sealed
                        position.</li>
                    </div>
                    <div class="title1">Scope of Application</div>
                    <div class="title2">
                      <li>Re-frac of old wells: Plug the original fracture, and form new fractures</li>
                      <li>Diverting frac of new wells: achieve multi fracture fracturing in one stage</li>
                      <li>Make up for that some of mechanical method cannot achieve</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="screenmake0" style="background-color: transparent;padding: 5vh 8vw;">
            <div class="heighLight">Service Capability
            </div>
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 20vh;" loading="lazy"
                  src="@/assets/subordinate/conventional2.png" />
              </div>
              <div>
                <p>Design capacity: abundant experiences of both domestic and abroad applications, familiar with various
                  conditions where is necessary to add diverting materials</p>
              </div>
            </div>
          </div>

          <div class="screenmake0" style="padding: 5vh 8vw; background-color: #bfd8f9;">
            <div class="lr-box rl">
              <div>
                <p>Application capacity: In depth study on different particle sizes, different combinations and
                  corresponding effects</p>
              </div>
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 20vh;" loading="lazy"
                  src="@/assets/subordinate/conventional3.png" />
              </div>
            </div>
          </div>

          <div class="screenmake0" style="background-color: white;padding: 5vh 8vw;">
            <div class="lr-box">
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 18vh;" loading="lazy"
                  src="@/assets/subordinate/conventional4.png" />
              </div>
              <div>
                <p>Product features: good sealing property; adjustable dissolution time; diverse products</p>
              </div>
            </div>
          </div>

          <div class="screenmake0" style="padding: 5vh 8vw;">
            <div class="lr-box rl">
              <div>
                <p>Characteristic knot temporary plugging and turning technology: through the special-shaped soluble
                  temporary plugging to plug the hole or near wellbore fractures, can be embedded in different shapes of
                  holes, and more pressure it applies, the diverter becomes harder. It solves the problems of
                  conventional balls are easy to fall off etc.</p>
              </div>
              <div style="height: fit-content">
                <img style="border-radius: 9px;width: 100%;height: 15vh;background-color: #bfd8f9;" loading="lazy"
                  src="@/assets/subordinate/conventional5.png" />
              </div>
            </div>
          </div>

          <div class="screenmake0" style="background-color: transparent;">
            <div class="heighLight">Scope of Services</div>
            <div class="heightLight2" style="height: auto;width: unset;">
              <li>Temporary plugging fracturing job design, products and field services</li>
              <li>2020-2023, ANTON knot temporary plugging technology has been implemented more than 30 times in China ,
                more than 10,000 temporary knots sealer have been used in total , and the average pressure rise of each
                stage is more than 10MPa. The production after fracturing is 30-50% higher than that of the same type of
                wells in the same block</li>
              <li>More than 300 old wells re-frac in Xinjiang basin, Songliao Basin, Ordos basin, Bohai and other
                oilfields. Temporary plugging pressure increase > 3MPa with 98% success rate</li>
              <li>50 horizontal wells of temporary plugging and multi fracture fracturing. Temporary plugging pressure
                increase > 5MPa with 100% success rate</li>
            </div>
          </div>

          <bottomTip></bottomTip>
        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <headtip @showDialog="showPartner"></headtip>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">Composite Diverting Technology</div>
              <div class="title2">
                This is a technology to change the direction of fracture propagation and promote initiating new
                fractures through temporary plugging agent. The fracture complexity and the stimulated volume of the
                reservoir will be remarkably improved by this technology to increase production.

              </div>
            </div>
            <div class="titlle3" @click="clickConsult('Geological Engineering GPTs')">
              Product inquiry</div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <!--Technical Principle  -->
          <div class="screenmake0">
            <div class="heighLight">Technical Principle
            </div>
            <div class="heightLight2">
              Soluble temporary plugging knot is a synthetic polymer with knot shape, inject soluble temporary plugging
              knot during fracturing (acid fracturing) process, effectively seal perforations and near wellbore fracture
              to divert fluid to initiate new fracture, improve homogeneous stimulation result of different properties,
              formation stress and clusters.
            </div>
          </div>
          <!--Application Method  -->
          <div class="screenmake0" style="background: transparent;">
            <div class="heighLight">Application Method
            </div>
            <div class="heightLight2">
              After the completion of fracturing in previous stage, the compound temporary plugging agent is pumped
              before pump new pad. The temporary plugging agent gathers at the designed position to form a sealing layer
              and increase downhole net pressure, change the extension direction of the fracture and promote the opening
              of new fractures. The compound temporary plugging agent breaks automatically after the completion of
              stimulation and restores the connectivity of the sealed position.
            </div>
          </div>
          <!-- Scope of Application -->
          <div class="screenmake0">
            <div class="heighLight">Scope of Application
            </div>
            <div class="heightLight2">
              <li>Re-frac of old wells: Plug the original fracture, and form new fractures</li>
              <li>Diverting frac of new wells: achieve multi fracture fracturing in one stage</li>
              <li>Make up for that some of mechanical method cannot achieve</li>
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/conventional1.png" />
            </div>
          </div>
          <!--Service Capability-->
          <div class="screenmake0" style="background: transparent;">
            <div class="heighLight">Service Capability
            </div>
            <div class="heightLight2">
              Design capacity: abundant experiences of both domestic and abroad applications, familiar with various conditions where is necessary to add diverting materials
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/conventional2.png" />
            </div>
          </div>
          <div class="screenmake0">
            <div class="heightLight2">
              Application capacity: In depth study on different particle sizes, different combinations and corresponding effects
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/conventional3.png" />
            </div>
          </div>
          <div class="screenmake0" style="background: transparent;">
            <div class="heightLight2">
              Product features: good sealing property; adjustable dissolution time; diverse products
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/conventional4.png" />
            </div>
          </div>
          <div class="screenmake0">
            <div class="heightLight2">
              Characteristic knot temporary plugging and turning technology: through the special-shaped soluble temporary plugging to plug the hole or near wellbore fractures, can be embedded in different shapes of holes, and more pressure it applies, the diverter becomes harder. It solves the problems of conventional balls are easy to fall off etc.
            </div>
            <div style="width: 100%;height: fit-content;text-align: center;">
              <img style="border-radius: 9px;width: 100%;" loading="lazy" src="@/assets/subordinate/conventional5.png" />
            </div>
          </div>
          <!-- Scope of Services -->
          <div class="screenmake0" style="background: transparent;">
            <div class="heighLight">Scope of Services
            </div>
            <div class="heightLight2">
              <li>Temporary plugging fracturing job design, products and field services</li>
              <li>2020-2023, ANTON knot temporary plugging technology has been implemented more than 30 times in China ,
                more than 10,000 temporary knots sealer have been used in total , and the average pressure rise of each
                stage is more than 10MPa. The production after fracturing is 30-50% higher than that of the same type of
                wells in the same block</li>
              <li>More than 300 old wells re-frac in Xinjiang basin, Songliao Basin, Ordos basin, Bohai and other
                oilfields. Temporary plugging pressure increase > 3MPa with 98% success rate</li>
              <li>50 horizontal wells of temporary plugging and multi fracture fracturing. Temporary plugging pressure
                increase > 5MPa with 100% success rate</li>
            </div>

          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>

        </div>
        <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
        <consultTip :dialogVisible="dialogVisible" :thisTitle="title" @hideDialog="hideDialog"></consultTip>
      </div>
    </div>
  </div>



</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import { shareUrl } from "@/utils/vxshare";
export default {

  name: "",

  props: [],

  components: { PartnerTip, consultTip, headtip, bottomTip },

  data() {
    return {
      title: '',
      pageTitle: 'Composite Diverting Technology',
      pageDescription: 'This is a technology to change the direction of fracture propagation and promote initiating new fractures through temporary plugging agent. The fracture complexity and the stimulated volume of the reservoir will be remarkably improved by this technology to increase production.',
      pageKeywords: 'Composite Diverting Technology,Geological Engineering GPTs',
      model: false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/subordinate/conventional6.png"),
        title1: '中石化东北局<br><br>',
        title3: "" +
          "<li>水平井，采取绳结暂堵转向工艺+限流射孔工艺，提高簇间封堵效果；</li>" +
          "<li>单段暂堵压力响应＞10MPa（最高 26MPa），结合微地震监测结果，总体反映暂堵本井暂堵效果较好；</li>" +
          "<li>该井产量相比邻井常规工艺 增加30%；</li>"
      },
      {
        img: require("@/assets/subordinate/conventional7.png"),
        title1: '中石化华北局<br><br>',
        title3: "" +
          "<li>单井12段39簇压裂，每段均采取绳结暂堵技术，暂堵效果良好，监测结果显示转簇及两翼改善效果明显，两翼半长偏差 低于10%，有效提高储层平面改造程度，同时降低了与周边老井压窜可能性；</li>" +
          "<li>该井测试日产量19万方/天，创下该区块单井产气量记录；</li>"
      },
      ]
    }

  },


  methods: {
    clickConsult(item) {

      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    realtime() {
      window.open("/realtime")
    },
    showPartner() {
      this.dialogconsult = true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    }, setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: this.pageTitle, //'智能工程迭代优化GPTs-工程地质技术GPTs-地质工程云平台GPT',
        pageDescription: this.pageDescription, //'依托云的管理模式，工程服务与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议，以达到提升工程效果，降低工程风险的效果。',
        pageKeywords: this.pageKeywords, //'智能工程迭代优化GPTs,工程地质技术GPTs,地质工程云平台GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
img {
  object-fit: contain;
  background-color: #fff;
}

.totalmakeHole {
  background: #c0d8f9;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 74vh;

      padding: 2.84301606922126vh 1.5vw 2.84301606922126vh 5vw;
      background-image: url("../../../assets/subordinate/connect22.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, #becff5 30%, rgba(198, 211, 239, 0.5));
      }

      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 3vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: left;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }

        .titlle3 {
          margin-top: 5vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }

    .screenGpts {
      z-index: 10;
      width: 100vw;
      height: fit-content;
      border-radius: 9px;
      padding: 4.78739184177998vh 8.09763142692085vw;
      background: transparent;

      .title {

        height: 8.23733003708282vh;
        opacity: 1;
        font-size: 1.5vw;
        //color: #356ff8;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 4.23733003708282vh;
      }

      .light {
        margin: 0 auto 4vh auto;
        text-align: left;
        width: 66vw;
        height: fit-content;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        //color: #3172dd;
        line-height: 4.94437577255871vh;

      }


      .pro3 {
        //margin-top: -4vh;
        height: fit-content;
        width: 100%;
        z-index: 89156;

        grid-template-columns: minmax(0, 1fr);
        row-gap: 2vh;

        .grand2 {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.52432120161756vw 0.98887515451174vh 0.39324090121317vw;
          opacity: 1;

          .back {
            transition: transform 0.5s ease;
            width: 100%;
            position: relative;
            height: 100%;
            background: white;
            box-shadow: 5px 0 14px 0px #D4E4FF;
            border: 2px solid #FFFFFF;
            border-radius: 9px;
            display: flex;
          }

          .photo {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            /* 这里设置背景颜色为透明 */
            opacity: 1.25;
            margin: 0 auto auto auto;
            display: flex;
            justify-content: center;
            text-align: center;
            width: fit-content;
            height: 100%;
          }


          .title1 {
            padding: 0;
            opacity: 4;
            text-align: center;
            font-size: 1vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: bold;
            //color: #2168DB;
            line-height: 5vh;
          }

          .title2 {
            opacity: 4;
            text-align: left;
            justify-content: center;
            align-items: center;
            font-size: 0.9vw;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            //color: #2168DB;
            line-height: 2.71940667490729vh;
          }

          .titleDesign {
            margin: auto;
            padding: 0 3vw;
            //width: 50%;
            //float: right;
            text-align: left;
          }

          .consult {
            position: absolute;
            display: none;
            font-family: Sarial, sans-serif, "Microsoft Yahei";
            font-weight: 500;
            font-size: 0.9vw;
            bottom: 3.94437577255871vh;
            left: 1.85540150202195vw;
          }
        }

        .grand2:hover {
          .back {
            transform: translateY(-10px);

          }

        }
      }



    }

    .screenmake4 {
      padding: 5vh 3.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 5.23733003708282vh;
        font-size: 1.5vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: bold;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 68vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              background-color: white;
              width: fit-content;
              height: 100%;
              margin: 0 auto;
              opacity: 1;
            }

            .titleDesign {
              margin: auto;
              color: white;
              width: 35%;
              float: right;
              text-align: left;

              .title1 {
                line-height: 4vh;
                font-size: 1.1709416522vw;
              }

              .title2 {
                line-height: 3.5vh;
                font-size: 0.9vw;

                ::v-deep li {
                  margin-top: 5px;
                }

                ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 70vh !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }

    .screenmake0 {
      padding: 5vh 15.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height: 6.23733003708282vh;
        opacity: 1;
        font-weight: bold;
        font-size: 1.5vw;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin: 0 auto 20px;
        // text-align: center;
        height: 8.78739184177998vh;
        // font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 66.87144622991347vh;
            overflow: hidden;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              background-image: url("../../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 1.5248454883vw;
              margin: 0 1vw auto auto;
              color: black;
              width: 40%;
              float: right;

              .title1 {
                margin-top: 2vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }

              .title2 {
                margin-top: 1vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
                ;
              }

            }

          }
        }


      }
    }
  }

}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    height: 100vh;

    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 450px;
      padding: 2.84301606922126vh 0 2.84301606922126vh 0;
      background-image: url("../../../assets/subordinate/connect22.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;

      /* Add any additional styling as needed */
      &>div {
        position: relative;
        z-index: 10;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, rgb(190, 207, 245) 40%, rgba(198, 211, 239, 0.5)),
          linear-gradient(to bottom, rgba(190, 207, 245, 0.5), rgba(198, 211, 239, 0.3));
        /* 从上到下的渐变 */
      }

      .layer2 {
        position: absolute;
        top: 75px;
        width: 100%;

        .title1 {
          width: fit-content;
          text-align: center;
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }

        .title4 {
          margin-top: 15px;
          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size: 16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }
      }

      .titlle3 {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }

    .screenmake0 {
      width: 100vw;
      height: fit-content;
      padding: 20px 4.6875vw;
      background: white;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 20px;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 26px;

      }

      .heightLight2 {
        margin: 19px 2vw;
        text-align: left;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 21px;

        h4 {
          margin-bottom: 3px;
        }

        p {
          margin-bottom: 2px;
          line-height: 21px;
        }
      }

      .grid {
        position: relative;
        height: fit-content;
        margin-top: 25px;

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}

.lr-box {
  display: flex;
  align-items: center;
  margin-top: 3vh;

  h4 {
    font-size: 1.2vw;
    margin-bottom: 3vh;
  }

  p {
    margin-bottom: .5vh;
    font-size: 1vw;
    line-height: 2;
  }

  &>div {
    flex: 1;
  }

  &>div:first-child {
    width: 50%;
    margin-right: 3vw;
  }
}
</style>
