import { render, staticRenderFns } from "./heavyoil.vue?vue&type=template&id=c4ab4866&scoped=true"
import script from "./heavyoil.vue?vue&type=script&lang=js"
export * from "./heavyoil.vue?vue&type=script&lang=js"
import style0 from "./heavyoil.vue?vue&type=style&index=0&id=c4ab4866&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4ab4866",
  null
  
)

export default component.exports