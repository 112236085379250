var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.model === false)?_c('div',[_c('div',{staticClass:"totalmakeHole"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"screen1"},[_c('headtip',{on:{"showDialog":_vm.showPartner}}),_c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("ANTON Intelligent Micro-seismic Technology ")]),_c('div',{staticClass:"title2"},[_vm._v(" This is an intelligent three-component node seismograph specifically developed for real-time surface monitoring in oil and gas fracturing. ")]),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Geological Engineering GPTs ')}}},[_vm._v(" Product Inquiry ")])]),_c('div',{staticClass:"layer3"})],1),_vm._m(0),_c('div',{ref:"consultMove",staticClass:"screenGpts"},[_vm._m(1),_vm._m(2)]),_vm._m(3),_c('div'),_c('bottomTip')],1),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)]):_c('div',[_c('div',{staticClass:"totalmakeHoleModel"},[_c('div',{staticClass:"makeHole1"},[_c('div',{staticClass:"screen1"},[_c('div',{staticStyle:{"margin":"0 15px"}},[_c('headtip',{on:{"showDialog":_vm.showPartner}})],1),_vm._m(4),_c('div',{staticClass:"titlle3",on:{"click":function($event){return _vm.clickConsult('Geological Engineering GPTs ')}}},[_vm._v(" Product Inquiry ")]),_c('div',{staticClass:"layer3"})]),_vm._m(5),_vm._m(6),_vm._m(7),_c('div'),_c('div',{ref:"bottom"},[_c('bottomTip')],1)]),_c('partnerTip',{attrs:{"dialogVisible":_vm.dialogconsult},on:{"hideDialog":_vm.hide}}),_c('consultTip',{attrs:{"dialogVisible":_vm.dialogVisible,"thisTitle":_vm.title},on:{"hideDialog":_vm.hideDialog}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"heighLight"},[_vm._v("Technical Principle")]),_c('div',{staticClass:"heightLight2",staticStyle:{"height":"auto"}},[_c('li',[_vm._v("This is an intelligent three-component node seismograph specifically developed for real-time surface monitoring in oil and gas fracturing. The product's advantages include high sensitivity in three components, 4G/5G remote real-time transmission, and the ability to perform ultra-long continuous field operations for up to 150 days with an external battery pack. When connected to a solar panel, it can achieve truly all-weather, real-time intelligent monitoring of micro-seismic events and advanced seismic source mechanism interpretation for fracture imaging;")]),_c('li',[_vm._v("This product is widely used for real-time micro-seismic monitoring in shale gas, shale oil, tight gas, coalbed methane, CO"),_c('sub',[_vm._v("2")]),_vm._v(" storage and migration, gas storage, and oil storage. It is also used for real-time geological disaster monitoring in oil pipeline areas, as well as by research institutions and major petroleum and geological universities;")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin":"0 auto"}},[_c('div',{staticStyle:{"display":"flex","margin":"0 auto 0 auto","text-align":"center"}},[_c('div',{staticClass:"title"},[_vm._v(" Technical Characteristics ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pro3"},[_c('div',{staticClass:"grand2"},[_c('div',{staticClass:"back"},[_c('div',{staticClass:"photo",staticStyle:{"background-color":"white","padding":"1vh 0.5vw","width":"60%"}},[_c('img',{staticStyle:{"border-top-left-radius":"9px","border-top-right-radius":"9px","height":"100%"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/microseismic1.png")}})]),_c('div',{staticClass:"titleDesign"},[_c('div',{staticClass:"title2",staticStyle:{"line-height":"4vh"}},[_c('li',[_vm._v("Real-time Data Transmission: Through 4G or 5G real-time data transmission, true real-time monitoring of data quality during fracturing is achieved. Supervisors no longer need to be on-site and can remotely control the project progress at any time;")]),_c('li',[_vm._v("Intelligent Remote Sleep Control: With ultra-low power consumption, the intelligent remote sleep control function can meet the real-time monitoring needs of fracturing for 25-30 days. When connected to an external battery, it can provide up to 150 days of extended standby;")]),_c('li',[_vm._v("Anti-theft and Tracking: Equipped with intelligent anti-theft alarms and movement tracking features, it prevents economic losses caused by equipment theft;")]),_c('li',[_vm._v("Simple System Design: The system is small, lightweight, and all modules and functions are integrated into a single data acquisition station. There are no unnecessary components, and the status of the equipment can be viewed and operated via mobile phones, tablets, or computers. This setup increases deployment efficiency by at least 300% and reduces costs by at least 50%; ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"heighLight"},[_vm._v("Software Advantages")]),_c('div',{staticClass:"heightLight2",staticStyle:{"height":"auto"}},[_c('li',[_vm._v("High Accuracy: When the surface micro-seismic monitoring observation system is deployed correctly, the horizontal positioning error of micro-seismic events (X, Y) is ≤5m, and the vertical positioning error (Z) is ≤40m;")]),_c('li',[_vm._v("Real-time Data Processing: During fracturing operations, the collected micro-seismic data is processed in real-time to identify and locate micro-seismic events generated during the fracturing process. On-site, basic interpretation of the processed micro-seismic data is performed to describe fracture length, height, width, orientation, and location as fracturing progresses. The data is also presented in 3D display diagrams and video formats, showing the spatial distribution of micro-seismic events over time. The spatial distribution of fractures around the well is interpreted to analyze the fracture propagation patterns and extension characteristics;")]),_c('li',[_vm._v("DFN Modeling: Using the located micro-seismic event points, Discrete Fracture Network (DFN) modeling is conducted. Based on the DFN model, hydraulic fracture permeability is inversed to evaluate the fracture conductivity of the near-wellbore reservoir after fracturing, with a permeability inversion accuracy of 0.01 mD;")]),_c('li',[_vm._v("Seismic Wave Analysis: The S/P spectral ratio is calculated based on the P-wave and S-wave arrival times of micro-seismic events. The distribution of tensile/wet events (inflow fractures) and shear/dry events (non-inflow fractures) is distinguished using the S/P spectral ratio and S-wave corner frequency distribution. The distribution range of inflow fractures is used to calculate the Effective Stimulated Reservoir Volume (ESRV);")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer2"},[_c('div',{staticClass:"title1"},[_vm._v("ANTON Intelligent Micro-seismic Technology")]),_c('div',{staticClass:"title2"},[_vm._v("This is an intelligent three-component node seismograph specifically developed for real-time surface monitoring in oil and gas fracturing. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("Technical Principle ")]),_c('div',{staticClass:"heightLight2"},[_c('li',[_vm._v("This is an intelligent three-component node seismograph specifically developed for real-time surface monitoring in oil and gas fracturing. The product's advantages include high sensitivity in three components, 4G/5G remote real-time transmission, and the ability to perform ultra-long continuous field operations for up to 150 days with an external battery pack. When connected to a solar panel, it can achieve truly all-weather, real-time intelligent monitoring of micro-seismic events and advanced seismic source mechanism interpretation for fracture imaging;")]),_c('li',[_vm._v("This product is widely used for real-time micro-seismic monitoring in shale gas, shale oil, tight gas, coalbed methane, CO"),_c('sub',[_vm._v("2")]),_vm._v(" storage and migration, gas storage, and oil storage. It is also used for real-time geological disaster monitoring in oil pipeline areas, as well as by research institutions and major petroleum and geological universities;")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0",staticStyle:{"background":"transparent"}},[_c('div',{staticClass:"heighLight"},[_vm._v(" Technical Characteristics ")]),_c('div',{staticClass:"heightLight2"},[_c('li',[_vm._v("Real-time Data Transmission: Through 4G or 5G real-time data transmission, true real-time monitoring of data quality during fracturing is achieved. Supervisors no longer need to be on-site and can remotely control the project progress at any time;")]),_c('li',[_vm._v("Intelligent Remote Sleep Control: With ultra-low power consumption, the intelligent remote sleep control function can meet the real-time monitoring needs of fracturing for 25-30 days. When connected to an external battery, it can provide up to 150 days of extended standby;")]),_c('li',[_vm._v("Anti-theft and Tracking: Equipped with intelligent anti-theft alarms and movement tracking features, it prevents economic losses caused by equipment theft;")]),_c('li',[_vm._v("Simple System Design: The system is small, lightweight, and all modules and functions are integrated into a single data acquisition station. There are no unnecessary components, and the status of the equipment can be viewed and operated via mobile phones, tablets, or computers. This setup increases deployment efficiency by at least 300% and reduces costs by at least 50%;")])]),_c('div',{staticStyle:{"width":"100%","height":"fit-content","text-align":"center"}},[_c('img',{staticStyle:{"border-radius":"9px"},attrs:{"loading":"lazy","src":require("@/assets/subordinate/microseismic1.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenmake0"},[_c('div',{staticClass:"heighLight"},[_vm._v("Software Advantages ")]),_c('div',{staticClass:"heightLight2"},[_c('li',[_vm._v("High Accuracy: When the surface micro-seismic monitoring observation system is deployed correctly, the horizontal positioning error of micro-seismic events (X, Y) is ≤5m, and the vertical positioning error (Z) is ≤40m;")]),_c('li',[_vm._v("Real-time Data Processing: During fracturing operations, the collected micro-seismic data is processed in real-time to identify and locate micro-seismic events generated during the fracturing process. On-site, basic interpretation of the processed micro-seismic data is performed to describe fracture length, height, width, orientation, and location as fracturing progresses. The data is also presented in 3D display diagrams and video formats, showing the spatial distribution of micro-seismic events over time. The spatial distribution of fractures around the well is interpreted to analyze the fracture propagation patterns and extension characteristics;")]),_c('li',[_vm._v("DFN Modeling: Using the located micro-seismic event points, Discrete Fracture Network (DFN) modeling is conducted. Based on the DFN model, hydraulic fracture permeability is inversed to evaluate the fracture conductivity of the near-wellbore reservoir after fracturing, with a permeability inversion accuracy of 0.01 mD;")]),_c('li',[_vm._v("Seismic Wave Analysis: The S/P spectral ratio is calculated based on the P-wave and S-wave arrival times of micro-seismic events. The distribution of tensile/wet events (inflow fractures) and shear/dry events (non-inflow fractures) is distinguished using the S/P spectral ratio and S-wave corner frequency distribution. The distribution range of inflow fractures is used to calculate the Effective Stimulated Reservoir Volume (ESRV);")])])])
}]

export { render, staticRenderFns }